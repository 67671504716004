@import "../../../../../../app/styles/index";

$gradientButtonActive: linear-gradient(
	94.3deg,
	#ffa800 -7.75%,
	#ff4d00 137.22%
);
.btn {
	width: 198px;
	height: 47px;
	cursor: pointer;
	position: relative;
	border-radius: 51px;
	font-size: $contetnFontSizeSmaller;
	font-family: $subMainFontFamily;
	background: linear-gradient(#21132e 0 0) padding-box,
		/*this is your grey background*/
			linear-gradient(
				198.57deg,
				#ebb16d 12.58%,
				rgba(214, 115, 0, 0.58) 113.05%
			)
			border-box;
	// font-weight: bold;
	color: $colorMainText;

	&:disabled {
		opacity: 0.5;
	}

	// background: transparent;
	border: 2px solid transparent;
	display: inline-block;

	&:hover {
		background: linear-gradient(#1d193b00 0 0) padding-box,
			linear-gradient(
					198.57deg,
					#ebb16d 12.58%,
					rgba(214, 115, 0, 0.58) 113.05%
				)
				border-box;
	}
}
