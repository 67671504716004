//main
$mainFontFamily: "Gamer Station";
$subMainFontFamily: "Montserrat", sans-serif;

$contetnFontSize: 18px;
$contetnFontSizeSmaller: 16px;
$contetnFontSizeSmallerInput: 12px;
$titleFontSize: 20px;
$titleBlockFontSize: 32px;

$colorMainText: #ffffff;
$gradientBtnGame: linear-gradient(
	180deg,
	rgba(255, 168, 0, 0.9) 0%,
	rgba(255, 77, 0, 0.9) 100%
);
$gradientMainBtn: linear-gradient(360deg, #ffa800 -91.38%, #ff4d00 159.2%);
$gradientSubBtn: linear-gradient(
	180deg,
	rgba(255, 168, 0, 0.8) 0%,
	rgba(255, 77, 0, 0.8) 100%
);
$gradientMainText: linear-gradient(180deg, #ffa800 0%, #ff4d00 100%);

$opacityMainText: 0.8;
