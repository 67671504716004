@import "/src/app/styles/index";

.content {
	width: 100%;
	display: flex;
	// justify-content: flex-end;
	flex-direction: column;
	align-items: center;
	text-align: center;
	margin-top: 200px;
	// display: none;
	position: relative;
	@include mediaMax(1250px) {
		margin-top: 100px;
	}
}

.title {
	text-align: left;
	z-index: 3;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;

	// max-width: 680px;
}

.titleSeason {
	display: flex;
	justify-content: center;
	align-items: start;

	.titleSeasonText {
		top: 45px;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: absolute;

		font-size: 30px;
		@include mediaMax($screenLg) {
			font-size: 24px;
			top: 15px;
		}
		@include mediaMax($screenMd) {
			font-size: 24px;
			top: 15px;
		}
	}

	@include mediaMax(1300px) {
		img {
			width: 100%;
			height: 250px;
		}
	}
	@include mediaMax($screenLg) {
		img {
			width: 100%;
			height: 200px;
		}
	}
	@include mediaMax($screenMd) {
		img {
			width: 105%;
			// height: 200px;
		}
	}
}

.socialNetwork {
	margin-top: 15px;
	@include mediaMax($screenLg) {
		margin-top: 10px;
	}
}

.textMain {
	font-size: 56px;
	font-family: $mainFontFamily;
	font-style: normal;
	text-align: center;
	font-weight: 400;
	line-height: 61px;
	background: linear-gradient(91.42deg, #ebb16d -5.95%, #e7e4e2 107.49%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
	filter: drop-shadow(3px 1px 0px #7c4300);
	@include mediaMax($screenLg) {
		font-size: 40px;
	}
	@include mediaMax($screenMd) {
		font-size: 32px;
		// line-height: 31px;
	}
}

.textSubMain {
	font-style: normal;
	text-align: center;
	line-height: 61px;
	background: linear-gradient(91.42deg, #ebb16d -5.95%, #e7e4e2 107.49%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
	filter: drop-shadow(3px 1px 0px #7c4300);
	font-weight: 700;
	@include mediaMax($screenLg) {
		margin-top: -20px;
	}
	@include mediaMax($screenMd) {
		font-size: 18px;
	}
}

.garlandLeft {
	position: absolute;
	z-index: 5;
	left: -150px;
	top: -20px;
	@include mediaMax(1250px) {
		width: 30%;
	}
}

.garlandRight {
	position: absolute;
	z-index: 5;
	transform: scale(-1, 1);
	right: -150px;
	top: -20px;
	@include mediaMax(1250px) {
		width: 30%;
	}
}
