@import "../../../../app/styles/index";

.modal {
	display: grid;
	font-family: $subMainFontFamily;
	font-style: normal;
	width: 434px;
	height: 60px;
	color: #ffffff;
	justify-items: center;
	background: #281f41;
	border-radius: 11px;

	div {
		display: grid;
		grid-template-columns: auto auto;
		column-gap: 20px;
		align-items: center;
		cursor: pointer;
		justify-items: start;
		margin-right: 70px;

		img {
			margin-left: 100px;
		}
	}

	@include mediaMax(570px) {
		width: 310px;
		div {
			margin-left: -10px;

			img {
				margin-left: 20px;
			}
		}
	}
}

.wDiv {
	width: 434px;
	display: grid;
	grid-auto-flow: column;
	justify-content: center;
}

.tronLink {
	margin-left: 126px;
	@include mediaMax(570px) {
		margin-left: 20px;
	}
}

.myNearWallet {
	height: 40px;
}

.neoIcons,
.near,
.velas {
	height: 50px;
}

.velasPay {
	cursor: pointer;
	width: 208px;
	height: 60px;

	font-weight: 700;
	font-size: 14px;
	line-height: 140%;

	background: #281f41;
	border-radius: 11px;
	display: grid;
	align-items: center;
	justify-items: center;

	div {
		display: grid;
		align-items: center;
		justify-items: center;
		grid-template-columns: 50px 96px;
		column-gap: 18px;
	}

	@include mediaMax(570px) {
		width: 150px;
		div {
			column-gap: 5px;
		}
	}
}

.buttonsNear {
	display: grid;
	grid-auto-columns: 208px;
	column-gap: 20px;
}

.nearButton {
}

.modalVelas {
	display: grid;
	grid-template-columns: 208px 208px;
	column-gap: 18px;
	position: relative;
	@include mediaMax(570px) {
		grid-template-columns: 150px 150px;
		column-gap: 10px;
	}
}
