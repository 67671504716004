@import "../../../../../app/styles/index";

.modal {
	display: grid;
	font-family: $subMainFontFamily;
	font-style: normal;
	width: 700px;
	height: 250px;
	color: #ffffff;
	justify-items: center;
	background: #0b0027;
	@include mediaMax(780px) {
		width: 300px;
	}
}

.title {
	margin-top: -20px;
	margin-bottom: 24px;
	font-weight: 800;
	font-size: 32px;
	line-height: 127%;
	@include mediaMax(780px) {
		font-size: 26px;
	}
}

.subTitle {
	font-weight: 500;
	font-size: 16px;
	line-height: 140%;
	text-align: center;
	width: 328px;
	margin-top: 40px;
	@include mediaMax(780px) {
		width: 200px;
	}
}

//.loader {
//  border: 16px solid #EBB16D;
//  border-top: 16px solid #f3f3f351;
//  border-radius: 50%;
//  width: 112px;
//  height: 112px;
//  animation: spin 1s linear infinite;
//}
//@keyframes spin {
//  0% {
//    transform: rotate(0deg);
//  }
//  100% {
//    transform: rotate(360deg);
//  }
//}
.loader {
	width: 112px;
	height: 112px;
	border: 16px solid #ebb16d;
	border-bottom-color: transparent;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
