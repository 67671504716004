@import "../../../../../../app/styles/index";

$gradientButtonActive: linear-gradient(
	94.3deg,
	#ffa800 -7.75%,
	#ff4d00 137.22%
);
.btn {
	width: 198px;
	height: 47px;
	cursor: pointer;
	position: relative;
	font-weight: 700;
	font-size: 16px;
	background: $gradientButtonActive;
	border-radius: 51px;
	font-family: $subMainFontFamily;
	// font-weight: bold;
	color: $colorMainText;

	&:disabled {
		opacity: 0.5;
	}

	background: linear-gradient(#1d193b00 0 0) padding-box,
		linear-gradient(198.57deg, #ebb16d 12.58%, rgba(214, 115, 0, 0.58) 113.05%)
			border-box;
	border: 2px solid transparent;
	display: inline-block;

	&:hover {
		background-size: 500px;
		background-position: center;
		animation: infinite 2s anim;
	}
}

@keyframes anim {
	0% {
		background-position: center;
	}
	35% {
		background-position: left;
	}
	75% {
		background-position: right;
	}
	100% {
		background-position: center;
	}
}
