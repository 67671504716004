@import "../../../../../app/styles/index";

.modal {
	display: grid;
	grid-template-columns: 295px auto;
	grid-template-rows: 60px auto;
	width: 1202px;
	height: 724px;
	font-family: $subMainFontFamily;
	font-style: normal;
	background: url("../../../assets/img/backgrounds/backgroundModalWindowPack.png")
		no-repeat;
	@include mediaMax(1250px) {
		width: 800px;
		background: url("../../../assets/img/backgrounds/background800px.png")
			no-repeat;
	}
	@include mediaMax(800px) {
		width: 340px;
		height: 600px;
		background: url("../../../assets/img/backgrounds/background340px600px.png")
			no-repeat;
		grid-template-columns: 345px;
		grid-template-rows: auto auto auto;
	}
}

.cross {
	position: absolute;
	right: 18px;
	top: 19px;
	cursor: pointer;
	height: 21px;
	width: 21px;
}

.title {
	height: 24px;
	grid-column: 1/3;
	margin-top: 30px;
	font-weight: 800;
	font-size: 32px;
	line-height: 127%;

	text-align: center;

	color: #ffffff;
}

.leftSide {
	display: grid;
	align-items: center;
	justify-items: center;
	margin-top: 60px;
	@include mediaMax(800px) {
		display: none;
	}
}

.titleItem {
	margin-bottom: 26px;
	font-weight: 800;
	font-size: 24px;
	line-height: 127%;

	color: #ffffff;
}

.areaForIcon {
	display: grid;
	align-items: center;
	justify-items: center;
	background: no-repeat
		url("../../../assets/img/png/areaForIconMysteryPackPurchase2.png");
	width: 89px;
	height: 89px;

	margin-bottom: 6px;
}

.iconMysteryPack {
	max-width: 80px;
	max-height: 80px;
}

.namePack {
	font-weight: 400;
	font-size: 16px;
	line-height: 127%;

	color: #ffffff;
	margin-bottom: 6px;
}

.wTypePack {
	font-style: italic;
	font-weight: 700;
	font-size: 12px;
	line-height: 12px;

	margin-bottom: 2px;
}

.priceOnePack {
	font-weight: 500;
	font-size: 13px;
	line-height: 127%;
	text-align: center;

	color: #ffffff;

	opacity: 0.7;

	margin-bottom: 0;
	margin-top: -50px;
}

.packDescription {
	width: 185px;

	font-weight: 500;
	font-size: 14px;
	line-height: 127%;
	text-align: center;

	color: #ffffff;
	margin-top: -20px;
	margin-bottom: 50px;
}

.subTitleIconPack {
	font-weight: 800;
	font-size: 14px;
	line-height: 127%;

	max-width: 270px;

	display: grid;
	grid-auto-flow: column;
	align-items: center;

	color: #ffffff;

	position: relative;
	left: -4px;
	margin-bottom: 16px;
}

.iconBlockchainPack {
	margin-right: 10px;
	width: 38px;
}

.totalAmount {
	position: relative;
	top: -10px;
	margin-bottom: 10px;
	font-weight: 700;
	font-size: 16px;
	line-height: 127%;

	color: #ffffff;
}

.buttonBack {
	cursor: pointer;
	font-weight: 700;
	font-size: 16px;
	line-height: 20px;

	display: grid;
	grid-auto-flow: column;
	justify-content: center;
	column-gap: 10px;
	align-items: center;
	justify-items: center;

	color: #ffffff;
	background: url("../../../assets/img/backgrounds/backgroundBackButton.png")
		no-repeat;
	width: 166px;
	height: 44px;
	margin-bottom: 50px;
}

.rightSide {
	margin-top: 60px;
	display: grid;
}

.titleRightSide {
	font-weight: 800;
	font-size: 24px;
	line-height: 127%;
	display: grid;
	align-items: center;
	text-align: center;

	color: #ffffff;
	align-self: start;

	@include mediaMax(1250px) {
		margin-left: 0;
	}
	@include mediaMax(800px) {
		width: 300px;
		margin-left: 20px;
		margin-top: -50px;
	}
}

.areaForSelect {
	display: grid;
	grid-template-columns: 374px 374px;
	grid-template-rows: 107px 107px 107px;
	column-gap: 14px;
	row-gap: 23px;
	justify-self: center;
	@include mediaMax(1250px) {
		grid-template-columns: auto;
		margin-left: -22px;
	}
}

.selectCryptocurrency {
	cursor: pointer;
	display: grid;
	grid-template-columns: 47px auto;
	align-items: center;
	justify-items: center;
	width: 374px;
	height: 107px;

	background: #281f41;
	border-radius: 18px;

	img {
		height: 50px;
		width: 50px;
		margin-left: 40px;
	}

	div {
		font-weight: 700;
		font-size: 14px;
		line-height: 140%;

		color: #ffffff;
		justify-self: start;
		margin-left: 38px;
	}

	@include mediaMax(1250px) {
		margin-left: 70px;
	}
	@include mediaMax(1250px) {
		margin-left: 20px;
		width: 300px;
	}
}

.priceInCrypto {
	margin-top: 10px;
}

.buttonPurchase {
	cursor: pointer;
	display: grid;
	align-items: center;
	justify-items: center;
	align-self: center;
	grid-row: 1/3;
	grid-column: 2;

	width: 166px;
	height: 44px;

	background: linear-gradient(176.42deg, #ebb16d -8.17%, #d67300 102.23%);
	box-shadow: 0 0 30px rgba(86, 176, 86, 0.19);
	border-radius: 21.5px;

	font-weight: 700;
	font-size: 16px;
	line-height: 20px;

	color: #ffffff;

	margin-top: 10px;
	@include mediaMax(1250px) {
		margin-left: 50px;
	}
	@include mediaMax(800px) {
		margin-top: -170px;
		margin-left: -40px;
	}
}

.wFooter {
	display: grid;
	grid-template-columns: auto auto;
}

.PriceInCryptoAndButtonPurchase {
	justify-self: end;
	display: grid;
	grid-template-columns: auto 166px;
	grid-template-rows: 18px 18px;
	align-items: center;
	justify-items: center;
	column-gap: 22px;
	row-gap: 8px;
	margin-right: 68px;
}

.PriceInCrypto2 {
	grid-column: 1;
	font-weight: 700;
	font-size: 24px;
	line-height: 140%;

	color: #ffffff;
}

.priceInCrypto1 {
	grid-column: 1;
	font-weight: 500;
	font-size: 14px;
	line-height: 140%;

	color: #ffffff;

	opacity: 0.8;
}

.line {
	position: absolute;
	top: 135px;
	left: 295px;
	@include mediaMax(800px) {
		display: none;
	}
}

.buttonBackH {
	display: none;
	@include mediaMax(800px) {
		cursor: pointer;
		font-weight: 700;
		font-size: 16px;
		line-height: 20px;

		display: grid;
		align-items: center;
		justify-items: center;

		color: #ffffff;
		background: url("../../../assets/img/backgrounds/backgroundBackButton.png")
			no-repeat;
		width: 166px;
		height: 44px;
		margin-bottom: 50px;

		position: absolute;
		margin-top: 500px;
		margin-left: 91px;
	}
}
