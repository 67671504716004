@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

@font-face {
	font-family: Gamer Station;
	src: url("./assets/fonts/gamer-station-a-playful-game-font/Gamer\ Station.ttf");
	font-style: normal;
}

.Toastify__toast-theme--light {
	background: url(modules/mainPage/assets/img/png/TabletTitle.png) no-repeat !important;
	background-image: url(modules/mainPage/assets/img/png/TabletTitle.png)
		no-repeat !important;
	background-size: 100% 100% !important;
	/* background: transparent !important; */
	opacity: 1 !important;
	z-index: 1000000000000 !important;
	color: rgb(255, 255, 255) !important;
	text-align: center;
	font-family: "Gamer Station" !important;
	font-style: normal;
	font-weight: 400;
	box-shadow: none !important;
}

.Toastify__toast-container {
	z-index: 1000000000000 !important;
}

.account-iframe {
	display: none;
}
