@import "/src/app/styles/index";

.header {
	z-index: 10;
	position: fixed;
	top: 0;
	width: 100%;
	max-width: 1440px;
	display: flex;
	justify-content: space-between;

	@include mediaMax(1150px) {
		padding: 0 22px 0 0;
	}
	// display: none;
}

.headerWrapper {
	display: flex;
	justify-content: center;
	position: relative;
}

.content {
	padding: 0 62px 10px;
	display: flex;
	z-index: 2;
	width: 100%;
	height: 90px;
	// border: #e7e4e2 2px solid;
	align-items: center;
	justify-content: space-between;
	@include mediaMax(1150px) {
		height: 80px;
		padding: 0;
	}
}

.headerImg {
	position: absolute;
	z-index: -1px;
	max-width: 1440px;
	object-fit: cover;
	// max-height: 11px;
	left: 50%;
	transform: translate(-50%, 0%);
	@include mediaMax(1150px) {
		height: 90px;
	}
}

.logo {
	z-index: 2;
	cursor: pointer;

	img {
		height: 81px;
		width: 167px;
	}
}

.tabs {
	display: flex;
	gap: 37px;
	font-weight: 800;
	font-size: 18px;
	line-height: 139%;
	/* or 25px */

	align-items: center;
	text-align: center;

	background: linear-gradient(184.31deg, #ebb16d -46.91%, #e7e4e2 118.36%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;

	filter: drop-shadow(-1px 2px 0px #684318);

	.tab {
		cursor: pointer;
		text-decoration: none;
	}

	@include mediaMax(1280px) {
		gap: 10px;
	}
	@include mediaMax(1150px) {
		display: none;
	}
}

.tabsMobile {
	display: flex;
	gap: 24px;
	font-weight: 800;
	font-size: 18px;
	line-height: 139%;
	/* or 25px */
	flex-direction: column;
	text-align: left;
	width: 364px;

	.WselectLanguage {
		display: block;
	}

	.tab {
		cursor: pointer;
		color: #e8ba83;
		text-decoration: none;
	}
}

.btn {
	cursor: pointer;
	@include mediaMax(1150px) {
		display: none;
	}
}

.menuIcon {
	display: none;
	@include mediaMax(1150px) {
		display: flex;
		cursor: pointer;
		justify-content: center;
		align-items: center;
	}
}

.menu {
	position: fixed;
	top: 80px;
	z-index: -1;
	right: 0;
	width: 300px;
	height: 420px;
	background: transparent;
	// border: #684318 1.5px solid;
	background-image: url(../../../../mainPage/assets/img/png/TabletBig.png);
	padding-left: 50px;
	padding-top: 30px;
	background-size: 100%;
	transition: 1s;
	animation: 1s anim2 ease-in-out;
	visibility: hidden;

	@include mediaMin(1150px) {
		display: none;
	}
}

.isOpenMenu {
	visibility: initial;
	animation: 1s anim ease-in-out;
}

@keyframes anim {
	0% {
		margin-right: -300px;
		// opacity: 0;
	}

	100% {
		margin-right: 0;
		opacity: 1;
	}
}

@keyframes anim2 {
	0% {
		margin-right: 0;
	}

	100% {
		margin-right: -300px;
		opacity: 1;
	}
}

.selectLanguage {
	display: grid;
	background: url("../../../../mainPage/assets/img/svg/borderForLanguage.svg")
		no-repeat;
	height: 37px;
	width: 67px;

	div {
		align-self: center;
		padding-top: 2px;
		padding-right: 16px;

		@include mediaMax(1150px) {
			padding-left: 10px;
		}
	}
}

.dropSelectLanguages {
	background: linear-gradient(180.04deg, #5d312e -14.66%, #be7861 115.72%);
	border-radius: 11px;
	position: absolute;
	height: 295px;
}

.WselectLanguage {
	cursor: pointer;
	font-weight: 800;
	font-size: 18px;
	line-height: 139%;
	/* or 25px */

	background: linear-gradient(184.31deg, #ebb16d -46.91%, #e7e4e2 118.36%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;

	filter: drop-shadow(-1px 2px 0px #684318);
	@include mediaMax(1280px) {
		gap: 10px;
	}
	@include mediaMax(1150px) {
		display: none;
	}
}
