@import "/src/app/styles/index";

.plate {
	position: relative;
}

.plateContent {
	position: absolute;
	z-index: 2;
	top: 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	height: 100%;
	align-items: center;
	width: 100%;
	@include mediaMax($screenSm) {
		justify-content: space-between;
		// padding-bottom: 200px;
		max-height: 460px;
	}
}

.titleBlock {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 50px;

	img {
		position: absolute;
		margin-top: -10px;
	}

	@include mediaMax($screenLg) {
		margin-top: 40px;
		img {
			height: 80px;
		}
	}
	@include mediaMax($screenSm) {
		margin-top: 100px;
		img {
			margin-top: -5px;
			height: 60px;
			width: 60%;
		}
		// margin-bottom: 15px;
	}
}

.title {
	text-align: center;
	font-family: $mainFontFamily;
	font-size: 56px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;

	background: linear-gradient(180deg, #a8610d 0%, #ebc190 100%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;

	filter: drop-shadow(6px 1px 0 #482904);
	@include mediaMax($screenLg) {
		// font-size: 16px;
		line-height: 35px;
		font-size: 32px;
	}
}

.text {
	margin-top: 30px;
	max-width: 900px;
	font-weight: 600;
	font-size: 18px;
	line-height: 180.5%;
	display: flex;
	align-items: center;
	text-align: center;
	color: #1c1209;
	@include mediaMax(1400px) {
		width: 70%;
	}
	@include mediaMax(1200px) {
		line-height: 160.5%;
	}
	@include mediaMax($screenLg) {
		font-size: 16px;
		width: 85%;
		line-height: 180.5%;
	}
	@include mediaMax($screenSm) {
		line-height: 150.5%;
		width: 83%;
		// margin-top: 2%;
		margin-top: 10px;
	}
}

.plateImg {
	width: 100%;
	display: flex;
	justify-content: center;
	@include mediaMax(1400px) {
		img {
			width: 110%;
			height: 450px;
		}
	}
	@include mediaMax($screenLg) {
		width: 120%;
		margin-left: -10%;
		margin-top: -100px;
		img {
			width: 120%;
			height: auto !important;
		}
	}
	@include mediaMax($screenSm) {
		margin-left: -5%;
		margin-top: 0;
		width: 110%;
		min-width: 370px;
		img {
			width: 100%;
		}
	}

	.plateImgMobile {
		display: none;
	}

	@include mediaMax($screenLg) {
		.plateImgDesktop {
			display: none;
		}
		.plateImgMobile {
			display: flex;
		}
	}
}
