@import "/src/app/styles/index";

.webp {
	#App {
	}
}

.no-webp {
	#App {
	}
}

.swiper-pagination {
	// margin-top: 200px !important;
	display: flex;
	justify-content: center;
	align-items: center;
	bottom: 0 !important;
}

.swiper-pagination-bullet {
	width: 10px !important;
	height: 10px !important;

	background: rgba(255, 255, 255, 0.27) !important;
	// opacity: 0.3 !important;
	@include mediaMin(1110px) {
		display: none !important;
	}
}

.swiper-pagination-bullet-active {
	width: 15px !important;
	height: 15px !important;
	opacity: 1 !important;
	background: linear-gradient(
		180deg,
		#ebb16d -38.96%,
		#d67300 103.89%
	) !important;
}
