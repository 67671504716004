@import "/src/app/styles/index";

.content {
	width: 100%;
	display: flex;
	// justify-content: flex-end;
	flex-direction: column;
	align-items: center;
	text-align: center;
	margin-top: 150px;
	position: relative;
	height: 100%;
	@include mediaMax(1140px) {
		margin-top: 50px;
	}
}

.title {
	text-align: left;
	z-index: 3;
	display: flex;
	align-items: center;
	gap: 37px;
	width: 100%;
	@include mediaMax(1140px) {
		width: 100%;
		text-align: center;
	}
	// max-width: 680px;
}

.titleSection {
	@include mediaMax(1140px) {
		width: 100%;
	}
}

.titleSeason {
	display: flex;
	justify-content: center;
	align-items: start;
	@include mediaMax(1200px) {
		display: none;
	}

	.titleSeasonText {
		position: absolute;
		margin-top: 8px;
		font-family: $mainFontFamily;
		font-style: normal;
		font-weight: 400;
		line-height: 61px;
		background: linear-gradient(91.42deg, #ebb16d -5.95%, #e7e4e2 107.49%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
		text-fill-color: transparent;
		filter: drop-shadow(3px 1px 0px #7c4300);
		font-size: 30px;
	}
}

.center {
	margin-top: 70px;
	position: relative;
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 180.5%;
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	color: #1c1209;
	width: 100%;
	height: 100%;
	// border: #e7e4e2 2px solid;
	@include mediaMax(1250px) {
		font-size: 20px;
	}
	@include mediaMax(1050px) {
		font-size: 14px;
	}
	@include mediaMax($screenMd) {
		// height: 80%;
		line-height: 17px;
		max-width: 550px;
	}
}

.mainImg {
	// @include mediaMax(1300px) {
	width: 100%;
	// }
	@include mediaMax($screenMd) {
		display: none;
	}
}

.mainImgMobile {
	width: 500px;
	@include mediaMin($screenMd) {
		display: none;
		width: 500px;
	}
	@include mediaMax($screenSm) {
		width: 390px;
	}
	min-width: 390px;
	max-width: 550px;
}

.textBlock {
	width: 68%;
	height: 87%;
	display: flex;
	// display: none;
	justify-content: center;
	align-items: center;
	position: absolute;
	// border: #e7e4e2 1px solid;
	div {
		max-width: 42%;
		// border: #e7e4e2 1px solid;
		padding: 0 10px;
	}

	@include mediaMax(1050px) {
		div {
			// max-width: 40%;
			padding: 0 10px;
		}
	}
	@include mediaMax($screenMd) {
		width: 440px;
		div {
			max-width: 45%;
		}
	}
	@include mediaMax($screenSm) {
		width: 340px;
	}
}

.centerText1 {
	position: absolute;
	top: 0;
	height: 14%;
	left: 0;
	margin-top: 30px;

	@include mediaMax(1250px) {
		margin-top: 15px;
	}
	@include mediaMax($screenMd) {
		left: 2px;
	}
	@include mediaMax($screenSm) {
		top: -4%;
		left: 0;
	}
}

.centerText2 {
	position: absolute;
	top: 0;
	height: 14%;
	right: -5px;
	margin-top: 30px;

	@include mediaMax(1250px) {
		margin-top: 15px;
	}
	@include mediaMax($screenMd) {
		right: 2px;
	}
	@include mediaMax($screenSm) {
		top: -4%;
		right: 0;
	}
}

.centerText3 {
	position: absolute;
	bottom: 31%;
	height: 14%;
	left: 0;
	margin-top: 30px;
	@include mediaMax($screenMd) {
		left: 2px;
	}
	@include mediaMax($screenSm) {
		bottom: 33%;
		left: 0;
	}
}

.centerText4 {
	position: absolute;
	bottom: 31%;
	height: 14%;
	right: -5px;
	margin-top: 30px;

	@include mediaMax(1250px) {
		margin-top: 0;
	}
	@include mediaMax($screenMd) {
		right: 2px;
	}
	@include mediaMax($screenSm) {
		bottom: 33%;
	}
}

.Garland {
	position: absolute;
	bottom: -350px;
	z-index: -1;
	left: -160px;
	@include mediaMax(1250px) {
		width: 200px;
		bottom: -150px;
		left: -30px;
	}
	@include mediaMax($screenMd) {
		width: 200px;
		bottom: -150px;
		left: -30px;
	}
	@include mediaMax($screenSm) {
		width: 200px;
		bottom: -150px;
		left: -30px;
	}
}
