@import "/src/app/styles/index";

.content {
	width: 100%;
	display: flex;
	// justify-content: flex-end;
	flex-direction: column;
	align-items: flex-end;
	text-align: right;
	margin-top: 300px;
	position: relative;
	@include mediaMax(1140px) {
		align-items: center;
		margin-bottom: 150px;
		margin-top: 50px;
	}
	@include mediaMax($screenMd) {
		margin-bottom: 80px;
	}
}

.rightBlock {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	text-align: right;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 180.5%;

	color: #ffffff;
	@include mediaMax(1200px) {
		margin-top: -30px;
	}
	@include mediaMax(1140px) {
		align-items: center;
		text-align: center;
	}
	@include mediaMax($screenLg) {
		font-size: 16px;
	}
}

.title {
	max-width: 680px;
	@include mediaMax(1200px) {
		max-width: 520px;
	}
	@include mediaMax(1140px) {
		text-align: center;
		max-width: 680px;
		margin-bottom: 700px;
	}
	@include mediaMax($screenSm) {
		margin-bottom: 430px;
	}
}

.text {
	max-width: 500px;
	margin-top: 60px;
	min-width: 360px;
	@include mediaMax(1200px) {
		max-width: 450px;
	}
	@include mediaMax(1140px) {
		text-align: center;
		max-width: 500px;
	}
}

.machine {
	position: absolute;
	left: -900px;
	top: -150px;
	@include mediaMax(1140px) {
		transform: translate(-61.3%, 0%) scale(0.8);
		left: 50%;
		top: 10px;
	}
	@include mediaMax($screenSm) {
		top: -120px;
		transform: translate(-58.3%, 0%) scale(0.5);
	}

	.spins {
		// position: relative;
	}

	.spin1 {
		position: absolute;
		top: 215px;
		left: 941px;
		z-index: 10;
	}

	.spin2 {
		position: absolute;
		top: 215px;
		left: 1045px;
		z-index: 10;
	}

	.spin3 {
		position: absolute;
		top: 215px;
		left: 1148px;
		z-index: 10;
	}

	.spins {
		img {
			height: 195px;
		}
	}
}
