@import "/src/app/styles/index";

footer {
	min-height: 300px;
	font-family: $subMainFontFamily;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 139%;
	/* or 22px */

	display: flex !important;
	align-items: center;
	justify-content: center;

	color: #ffffff;

	text-shadow: 0 0 200px #fd9e12;
}

.footer {
	width: 100vw;
	min-height: 431px;
	// background: #1a1922;
	display: flex;
	box-shadow: inset -70px -50px 250px rgba(112, 0, 255, 0.1),
		inset 70px 50px 250px rgba(255, 92, 0, 0.1);
	display: -webkit-flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.footerContainer {
		width: 100%;
		max-width: 1440px;
	}

	.logo {
		width: 185px;
		margin-bottom: 6px;
	}

	.footer__content {
		width: 100%;
		display: flex;
		display: -webkit-flex;
		justify-content: space-between;
		flex-wrap: wrap;

		> * + * {
			margin-left: 10px;
		}

		@include mediaMax(750px) {
			flex-direction: column-reverse;
			align-items: center;
			padding-bottom: 50px;
		}
	}

	.footer__subs {
		width: 50%;
		display: flex;
		display: -webkit-flex;
		justify-content: space-between;
		flex-wrap: wrap;

		> * + * {
			margin-right: 10px;
		}

		@include mediaMax(820px) {
			width: 40%;
		}
		@include mediaMax(750px) {
			// flex-direction: column;
			align-items: center;
			width: 300px;
			// justify-content: center;
		}
		@include mediaMax(520px) {
			// flex-direction: column;
			align-items: center;
			// width: 80%;
			// justify-content: center;
		}
	}

	.footer__logo {
		margin-top: -40px;
		margin-left: -10px;
		display: flex;
		@include mediaMax(750px) {
			margin: auto;
			justify-content: center;
			margin-left: -10px;
		}
	}

	.footer__main {
		display: flex;
		display: -webkit-flex;
		flex-direction: column;
		align-items: flex-start;
		text-align: left;

		> * + * {
			margin-top: 30px;
		}

		max-width: 340px;
		@include mediaMax(750px) {
			margin-top: 40px;
			align-items: center;
		}
	}

	.footer__madeby {
		margin-top: 15px;
		cursor: pointer;
		display: flex;
		display: -webkit-flex;
		align-items: center;
		transition: 0.5s;

		svg {
			transition: 0.5s;
			margin-left: 10px;
			margin-top: -3px;
		}

		&:hover {
			text-shadow: 0 0 10px rgba(255, 255, 255, 0.712);

			svg {
				transform: scale(1.2);
			}
		}
	}

	.footer__sub {
		cursor: pointer;
		display: flex;
		display: -webkit-flex;
		flex-direction: column;
		align-items: flex-start;

		> * + * {
			margin-top: 25px;
		}

		@include mediaMax(750px) {
			align-items: center;
			height: 270px;
		}
	}

	.footer__sub3In2Row {
		display: none;
		@include mediaMax(840px) {
			display: block;
		}
	}

	.footer__sub3 {
		@include mediaMax(840px) {
			flex-direction: row;
			align-items: center;
			width: 100%;
			display: none;
		}
	}

	.footer__text {
		@include mediaMax(750px) {
			text-align: center;
		}
	}

	.footer__btn {
		color: #fff;
		position: relative;
		text-decoration: none; /* no underline */
		svg {
			position: absolute;
			top: -10px;
			right: -10px;
		}
	}

	.footer__btn:hover {
		transition: 0.5s;
		color: #ffffff;
		opacity: 1 !important;
		text-shadow: 0 0 15px rgba(255, 255, 255, 0.41);
	}
}

.disabled {
	opacity: 0.5;
	pointer-events: none;
	position: relative;
}
