@import "../../../../../app/styles/index";

.wPacks {
	display: grid;
	grid-template-columns: 131px 131px 131px;
	justify-items: start;
	column-gap: 36px;
	@include mediaMax(1250px) {
		column-gap: 10px;
	}

	@include mediaMax(800px) {
		width: 140px;
		height: 200px;
		justify-items: center;
	}

	div {
		cursor: pointer;
	}
}

.body {
	@include mediaMax(1250px) {
		margin-left: 50px;
		margin-top: -20px;
	}
	@include mediaMax(800px) {
		width: 316px;
	}
}

.subTitle {
	margin-top: 50px;
	margin-bottom: 16px;
	font-weight: 700;
	font-size: 17px;
	line-height: 127%;
	/* or 22px */
	color: #ffffff;
	@include mediaMax(800px) {
		text-align: center;
	}
}

.packDescription {
	width: 640px;
	height: 100px;
	font-weight: 400;
	font-size: 16px;
	line-height: 127%;

	color: #ffffff;
	margin-top: 28px;
	margin-bottom: 24px;
	@include mediaMax(1250px) {
		width: 420px;
		font-size: 15px;
	}
	@include mediaMax(800px) {
		width: 314px;
		text-align: center;
	}
}

.pack {
	display: grid;
	width: 131px;
	height: 131px;

	background: #281f41;
	border-radius: 16px;
	align-items: center;
	justify-items: center;
}

.namePack {
	font-weight: 500;
	font-size: 16px;
	line-height: 127%;
	text-align: center;

	color: #ffffff;

	opacity: 0.8;

	margin-top: 16px;
}
