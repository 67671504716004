@import "/src/app/styles/index";

.btn {
	border: none;
	background: transparent;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	margin-top: 40px;
	width: 207px;
	height: 70px;
	cursor: pointer;
	z-index: -1;
}

.title {
	position: absolute;
	top: 4px;
	font-family: $mainFontFamily;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 20px;
	background: linear-gradient(180.69deg, #fff7ed -33.74%, #cfb392 98.68%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;

	filter: drop-shadow(1px 1px 0px #7c4300);
	transform: matrix(0.99, 0, 0.11, 1, 0, 0);
	z-index: 2;
}

.toast {
	width: 100%;

	height: 100%;
	// background-image: url(../../../../assets/imageNew/TabletMobile.png);
}
