@import "../../../../../app/styles/index";

.paragraph {
	font-weight: 500;
	font-size: 15px;
	line-height: 127%;
	width: 482px;
	margin-bottom: 46px;
	span {
		cursor: pointer;
		text-decoration: underline;
		font-weight: 700;
		color: rgb(231, 199, 157);
	}
}

.section {
	height: 280px;
}
