@import "/src/app/styles/index";

.content {
	margin-top: 150px;
	width: 100%;
	position: relative;
	@include mediaMax($screenMd) {
		margin-top: 0;
	}

	// pointer-events: none;
}

.parchmentsWrapper,
.titleSection {
	position: relative;
	@include mediaMax(1300px) {
		width: 100%;
		display: flex;
		justify-content: center;
	}
}

.title {
	display: flex;
	align-items: center;
	text-align: center;
}

.flare {
	position: absolute;
	top: -400px;
	left: -450px;
	pointer-events: none;
}

.parchments {
	z-index: 2;
	display: flex;
	justify-content: space-between;
	margin-top: 90px;
	position: relative;
	@include mediaMax(1300px) {
		flex-wrap: wrap;
		max-width: 650px;
		min-width: 360px;
	}
	@include mediaMax(720px) {
		justify-content: center;
		gap: 20px;
	}
	@include mediaMax(420px) {
		gap: 0;
	}

	.parchment > * {
		position: relative;
	}

	.parchment {
		z-index: 2;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 300px;
		height: 444px;
		position: relative;
		@include mediaMax(720px) {
			.parchmentImg {
				width: 309px;
				height: 296px;
			}
			width: 180px;
			height: 296px;
		}

		.parchmentContent {
			z-index: 2;
			width: 100%;
			height: 100%;
			display: flex;
			// justify-content: center;
			flex-direction: column;
			align-items: center;
			position: absolute;
			font-weight: 700;
			font-size: 32px;
			line-height: 180.5%;
			color: #1c1209;
			@include mediaMax(720px) {
				font-size: 22px;
			}

			.parchmentTitle {
				position: absolute;
				top: 70px;
				display: flex;
				justify-content: center;
				@include mediaMax(720px) {
					top: 60px;
				}

				// width: 200px;
			}

			.NFTPetsTitle {
				margin-left: 50px;
				@include mediaMax(720px) {
					margin-left: 30px;
				}
			}

			.parchmentImage {
				position: absolute;
				bottom: 70px;
				@include mediaMax(720px) {
					height: 100%;
					img {
						height: 40%;
					}
					// bottom: 0px;
					margin-bottom: -150px;
				}
			}

			.Boards {
				margin-left: -10px;
				@include mediaMax(720px) {
					bottom: 30px;
				}
			}

			.Characters {
				@include mediaMax(720px) {
					bottom: 55px;
					img {
						height: 50%;
						// height: 296px;
					}
				}
			}

			.GameItems {
				bottom: 60px;
				margin-left: 10px;
				@include mediaMax(720px) {
					bottom: 35px;
				}
			}

			.NFTPets {
				margin-left: 40px;
				bottom: 32px;
			}
		}
	}
}

.tablets {
	z-index: 2;
	display: flex;
	gap: 20px;
	// margin-left: -20px;
	margin-top: 120px;
	position: relative;
	@include mediaMax(1100px) {
		display: none;
	}

	.tablet {
		width: 235px;
		height: 90px;
		position: relative;
		cursor: pointer;

		img {
			z-index: 1;
		}

		div {
			z-index: 2;
		}
	}

	.title {
		width: 100%;
		position: absolute;
		display: flex;
		justify-content: center;
		font-weight: 700;
		font-size: 24px;
		line-height: 139%;
		background: linear-gradient(180deg, #ebb16d -14.71%, #e7e4e2 123.53%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		top: 28px;
	}
}

.tabletMobile {
	z-index: 2;
	position: relative;
	margin-top: 40px;

	img {
		z-index: 1;
	}

	div {
		z-index: 2;
	}

	.title {
		width: 100%;
		position: absolute;
		display: flex;
		justify-content: center;
		font-weight: 700;
		font-size: 24px;
		line-height: 139%;
		background: linear-gradient(180deg, #ebb16d -14.71%, #e7e4e2 123.53%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		top: 13px;
		@include mediaMax(1300px) {
			background: none;
			-webkit-background-clip: unset;
			-webkit-text-fill-color: unset;
			color: #efd49f;
		}
	}
}

.selectiveContent {
	z-index: 2;
	width: 100%;
	display: flex;
	flex-direction: column;
	position: relative;
	height: 100%;
	margin-top: 100px;
	@include mediaMax(1100px) {
		align-items: center;
		margin-top: 10px;
	}

	.selectiveTitle {
		font-family: $mainFontFamily;
		text-align: left;
		font-style: normal;
		font-weight: 400;
		font-size: 32px;
		line-height: 180.5%;
		background: linear-gradient(91.75deg, #ebb16d -4.59%, #e7e4e2 101.22%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
		text-fill-color: transparent;
		max-width: 600px;
		@include mediaMax(1100px) {
			font-size: 24px;
			margin-bottom: 350px;
			text-align: center;
			width: 100%;
		}
		@include mediaMax($screenMd) {
			margin-bottom: 340px;
		}
	}

	.text {
		z-index: 2;
		max-width: 500px;
		text-align: left;
		font-weight: 500;
		font-size: 18px;
		line-height: 180.5%;
		display: flex;
		align-items: center;
		color: #ffffff;
		margin-top: 40px;
		@include mediaMax(1100px) {
			text-align: center;
		}
		@include mediaMax($screenSm) {
			margin-top: 20px;
		}
	}
}

.btn {
	z-index: 1000;
	cursor: pointer;
	@include mediaMax(1100px) {
		display: flex;
		justify-content: center;
		margin-top: 40px;
	}
}

.imgCase {
	position: absolute;
	z-index: -1;
	pointer-events: none;
	@include mediaMax(1300px) {
		margin-left: -3%;
	}
	@include mediaMax(1100px) {
		left: 50% !important;
		transform: translate(-50%, 0%);
	}
}

.pieceMap {
	position: absolute;
	top: -450px;
	left: 50px;
	@include mediaMax(1250px) {
		top: -300px;
		left: 180px;
		img {
			height: 1000px;
		}
	}
	@include mediaMax(1100px) {
		top: -150px;
		transform: translate(-45%, 0%);
		img {
			height: 800px;
		}
	}
	@include mediaMax($screenSm) {
		top: -80px;
		transform: translate(-40%, 0%);
		img {
			height: 600px;
		}
	}
}

.dragon {
	position: absolute;
	top: -420px;
	left: 0;
	@include mediaMax(1250px) {
		top: -260px;
		left: 80px;
		img {
			height: 1100px;
		}
	}
	@include mediaMax(1100px) {
		top: -160px;
		transform: translate(-48%, 0%);
		img {
			height: 850px;
		}
	}
	@include mediaMax(1100px) {
		top: -50px;
		transform: translate(-46%, 0%);
		img {
			height: 640px;
		}
	}
}

.items {
	position: absolute;
	top: -260px;
	left: -100px;
	@include mediaMax(1250px) {
		top: -160px;
		left: 40px;
		img {
			height: 1000px;
		}
	}
	@include mediaMax(1100px) {
		top: -70px;
		transform: translate(-52%, 0%);
		img {
			height: 750px;
		}
	}
	@include mediaMax($screenSm) {
		top: 10px;
		transform: translate(-52%, 0%);
		img {
			height: 600px;
		}
	}
}

.avatars {
	position: absolute;
	top: -220px;
	left: 100px;
	@include mediaMax(1250px) {
		top: -160px;
		left: 170px;
		img {
			height: 900px;
		}
	}
	@include mediaMax(1100px) {
		top: -120px;
		// transform: translate(-52%, 0%);
		img {
			height: 730px;
		}
	}
	@include mediaMax($screenSm) {
		top: -30px;
		// transform: translate(-52%, 0%);
		img {
			height: 580px;
		}
	}
}

.inProgress {
	width: 174px;
	height: 43px;
	background: linear-gradient(93.77deg, #ffa800 -41.87%, #ff4d00 138.01%);
	border-radius: 21.5px;
	font-weight: 700;
	font-size: 18px;
	line-height: 139%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: #ffffff;
	margin-top: 30px;
}

.progressBar {
	width: 990px;
	position: absolute;
	bottom: -20px;
	padding-left: 10px;

	.progressBarActive {
		width: 990px;
		height: 4px;
		border-radius: 5px;
		background: linear-gradient(
			to right,
			rgba(255, 212, 0, 1),
			rgba(252, 134, 25, 1)
		);
	}

	.progressAuto {
		animation: infinite 10s progress linear;
	}
}

@keyframes progress {
	0% {
		width: 10px;
	}
	100% {
		width: 990px;
	}
}

.casesMobile {
	position: relative;
	width: 100%;
	height: 100%;
	// z-index: -1;

	@include mediaMin(1100px) {
		display: none;
	}

	.case1Mobile {
		position: relative;
	}

	.case2Mobile {
		position: relative;
	}

	.case3Mobile {
		position: relative;
	}

	.case4Mobile {
		position: relative;
	}
}

.cases {
	position: relative;
	width: 100%;
	height: 100%;
	// z-index: -1;
	@include mediaMax(1100px) {
		display: none;
	}

	.case1 {
		opacity: 0;
		transition: 1s;
		position: relative;

		&.active {
			opacity: 1;
		}

		&.auto {
			animation: infinite 10s progressImg1 linear;
		}

		.selectiveTitle {
			margin-top: -20px;
		}

		.btn {
			margin-top: 80px;
		}
	}

	.case2 {
		transition: 1s;
		position: absolute;
		top: -120px;
		opacity: 0;

		&.active {
			opacity: 1;
		}

		&.auto {
			animation: infinite 10s progressImg2 linear;
		}

		.btn {
			margin-top: 80px;
		}
	}

	.case3 {
		transition: 1s;
		position: absolute;
		top: -120px;
		opacity: 0;

		&.active {
			opacity: 1;
		}

		&.auto {
			animation: infinite 10s progressImg3 linear;
		}

		.btn {
			margin-top: 85px;
		}
	}

	.case4 {
		transition: 1s;
		position: absolute;
		top: -120px;
		opacity: 0;

		&.active {
			opacity: 1;
		}

		&.auto {
			animation: infinite 10s progressImg4 linear;
		}

		.btn {
			margin-top: 80px;
		}
	}
}

@keyframes progressImg1 {
	0% {
		opacity: 0;
	}
	5% {
		opacity: 1;
	}
	20% {
		opacity: 1;
	}
	25% {
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}

@keyframes progressImg2 {
	0% {
		opacity: 0;
	}
	20% {
		opacity: 0;
	}
	25% {
		opacity: 1;
	}
	45% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}

@keyframes progressImg3 {
	0% {
		opacity: 0;
	}
	45% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	75% {
		opacity: 1;
	}
	80% {
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}

@keyframes progressImg4 {
	0% {
		opacity: 0;
	}
	75% {
		opacity: 0;
	}
	80% {
		opacity: 1;
	}
	95% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
