@import "/src/app/styles/index";

.content {
	width: 100%;
	display: flex;
	// justify-content: flex-end;
	flex-direction: column;
	align-items: center;
	text-align: center;
	margin-top: 200px;
	position: relative;
	@include mediaMax($screenMd) {
		margin-top: 0;
	}
}

.title {
	text-align: center;
	z-index: 3;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 37px;
	width: 100%;
	@include mediaMax(1100px) {
		img {
			width: 700px;
		}
	}
	@include mediaMax($screenMd) {
		img {
			width: 382px;
		}
	}
	// max-width: 680px;
}

.titleSeason {
	display: flex;
	justify-content: center;
	align-items: start;

	.titleSeasonText {
		position: absolute;
		margin-top: 70px;
		font-family: $mainFontFamily;
		font-style: normal;
		font-weight: 400;
		font-size: 56px;
		line-height: 61px;
		background: linear-gradient(91.42deg, #ebb16d -5.95%, #e7e4e2 107.49%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
		text-fill-color: transparent;
		filter: drop-shadow(3px 1px 0px #7c4300);

		@include mediaMax(1100px) {
			margin-top: 30px;
			font-size: 40px;
		}
		@include mediaMax($screenMd) {
			margin-top: 5px;
			font-size: 32px;
		}
	}
}

.plateImg {
	height: 539px;
	@include mediaMax(1400px) {
		height: 437px;
	}
}

.plateImgCenter {
	width: 601px;
	height: auto;
	@include mediaMax(1400px) {
		width: auto;
		height: 477px;
	}
	@include mediaMax(1130px) {
		height: 437px !important;
	}
}

.center {
	margin-top: 270px;
	position: relative;
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 180.5%;
	display: flex;
	align-items: center;
	text-align: center;
	color: #1c1209;
	gap: 400px;
	@include mediaMax(1400px) {
		gap: 320px;
	}
	@include mediaMax(1130px) {
		display: none;
	}
}

.plate {
	display: flex;
	justify-content: center;
	position: relative;
}

.plateCenter {
	position: absolute;
	left: 50%;
	z-index: 2;
	transform: translate(-50%, -22%);
}

.bottomImg1 {
	position: absolute;
	bottom: 70px;
	@include mediaMax(1400px) {
		height: 80px;
		bottom: 55px;
	}
	// z-index: 1;
}

.bottomImg2 {
	position: absolute;
	bottom: 130px;
	@include mediaMax(1400px) {
		height: 100px;
		bottom: 85px;
	}
	@include mediaMax(1130px) {
		height: 90px;
		bottom: 60px;
	}
	// z-index: 1;
}

.bottomImg3 {
	position: absolute;
	bottom: 105px;
	@include mediaMax(1400px) {
		height: 100px;
		bottom: 95px;
	}
	// z-index: 1;
}

.plateTitle {
	font-family: $mainFontFamily;
	position: absolute;
	top: 50px;
	font-weight: 400;
	font-size: 32px;
	line-height: 35px;
	display: flex;
	align-items: center;
	text-align: center;
	background: linear-gradient(189.25deg, #a8610d -67.09%, #ebc190 140.61%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
	filter: drop-shadow(6px 1px 0px #482904);
	@include mediaMax(1400px) {
		top: 45px;
	}
}

.plateText {
	position: absolute;
	top: 92px;
	font-weight: 600;
	font-size: 18px;
	line-height: 180.5%;
	max-width: 366px;
	@include mediaMax(1400px) {
		max-width: 320px;
		line-height: 160%;
		margin-top: -15px;
	}
	@include mediaMax($screenLg) {
		font-size: 16px;
		line-height: 180.5%;
	}
}

.plateText2 {
	top: 112px;
	max-width: 443px;
	@include mediaMax(1400px) {
		max-width: 373px;
		margin-top: -30px;
	}
	@include mediaMax(1130px) {
		max-width: 346px;
	}
	@include mediaMax($screenLg) {
		max-width: 326px;
	}
}

.plateText3 {
	top: 112px;
	max-width: 386px;
	@include mediaMax(1400px) {
		max-width: 326px;
		margin-top: -30px;
	}
}

.btn {
	margin-top: -50px;
	position: relative;
	display: flex;
	justify-content: center;

	.btnFirework {
		position: absolute;
		z-index: -1;
		top: 10px;
	}

	@include mediaMax(1130px) {
		margin-top: 100px;
	}
}

.slider {
	margin-top: 40px;
	display: flex;
	width: 100%;
	min-width: 400px;
	@include mediaMin(1130px) {
		display: none !important;
	}
}

.mySwiper {
	height: 480px;
	display: flex;
	align-items: center;
	justify-content: center;
	// padding-top: 120px;
}
