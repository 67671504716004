@import "/src/app/styles/index";

.icons {
	display: flex;
	display: -webkit-flex;

	> * + * {
		margin-left: 23px;
	}
}

.icon {
	cursor: pointer;
	// opacity: 0.5;
	transition: 0.5s;
}

.icon:hover path {
	opacity: 1 !important;
	transition: 0.5s;
	fill: white;
}

.icon:hover {
	opacity: 1 !important;
	filter: drop-shadow(0 0 5px rgba(255, 253, 253, 0.615));
}
