@import "../../../../../app/styles/index";

.modal {
	width: 807px;
	height: 394px;
	display: grid;
	grid-template-rows: 23px 267px 34px;
	font-family: $subMainFontFamily;
	font-style: normal;
	align-items: center;
	justify-items: center;
	padding-top: 38px;
	background: url("../../../../common/general/assets/img/backgrounds/png/backgroundError.png");
}

.cross {
	position: absolute;
	right: 26px;
	top: 22px;
	cursor: pointer;
}

.description {
	text-align: center;
	font-weight: 400;
	font-size: 16px;
	line-height: 140%;

	color: #ffffff;
}

.title {
	font-weight: 800;
	font-size: 32px;
	line-height: 127%;
	text-align: center;

	color: #ffffff;
}
