@import "/src/app/styles/index";

.App {
	text-align: center;
	background-color: #1a1922;
	background-size: cover;
	// height: max;
	// min-height: 11000px;
	max-width: 100vw;
	// height: 5600px;
	font-family: $subMainFontFamily;
	display: flex;
	display: -webkit-flex;
	flex-direction: column;
	position: relative;
	align-items: center;
	// overflow: hidden;
	overflow: hidden;
	// position: fixed;
	// @include mediaMax($screenMd) {
	//   background-image: url(../assets/fonmobile.webp);
	//   background-image: url(../assets/FonMobile.png);
	// }
}

.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	width: 100%;
	// height:calc(100vh + 550px)
}

.topImage {
	min-width: 100vw;
	-webkit-transform: translate3d(0, 0, 0);
	width: 100%;
	// max-width: 100vw;
	display: flex;
	justify-content: center;
	height: 100vh;
	overflow: hidden;
	position: fixed;

	// max-width: 1440px;
	.topLayer {
		transition: opacity 0.5s, visibility 0s linear 0.5s;
		height: 100vh;
		z-index: 3;
		position: absolute;
		display: flex;
		gap: -5px;
		justify-content: center;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);

		img {
			transform: translate3d(0, 0, 0);
			-webkit-transform: translate3d(0, 0, 0);
			height: 100vh;
		}

		@include mediaMax(1000px) {
			width: 100%;
			display: flex;
			align-items: flex-end;

			img {
				object-fit: cover;
				width: 100%;
				height: 90%;
			}
		}
	}

	.bottomLayer {
		height: 100vh;
		width: 100%;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);

		img {
			-webkit-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
			z-index: 1000;
			height: 100vh;
			width: 100%;
			object-fit: cover;
			object-position: top;
		}
	}
}

.size {
	height: 100vh;
	width: 100%;
	position: fixed;
	display: none;
}

.main {
	margin-top: 130vh;

	width: 100%;

	position: relative;

	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	z-index: 9;
	display: flex;
	display: -webkit-flex;
	flex-direction: column;
	align-items: center;

	transition: 0.5s;

	.mainContent {
		width: 100%;
		display: flex;
		display: -webkit-flex;
		flex-direction: column;
		align-items: center;
	}
}

.topLayerBackLeft {
	position: absolute;
	z-index: 1;
	width: 50vw;
	left: -49.9vw;
	height: 100vh;
	background: #000;
}

.topLayerBackRight {
	position: absolute;
	z-index: 1;
	width: 50vw;
	right: -49.9vw;
	height: 100vh;
	background: #000;
}

.bottomLayerGrad {
	width: 100%;
	height: 50px;
	background: linear-gradient(to bottom, transparent, #1a1922);
	position: absolute;
	bottom: 0;
}

.footer {
	margin-top: auto;
	bottom: 0;
}

.trailer {
	position: fixed;
	right: 40px;
	bottom: 40px;
	z-index: 10;
	border-radius: 14px;
	border: 2px solid rgba(247, 221, 190, 0.4);
	width: 286px;
	height: 186px;
	// padding-bottom: 10%;
	overflow: hidden;
	cursor: pointer;

	@include mediaMax(558px) {
		display: none;
	}

	.cross {
		position: absolute;
		top: 10px;
		right: 10px;
		cursor: pointer;
	}

	.play {
		position: absolute;
		top: 68px;
		left: 118px;
		cursor: pointer;
	}

	& > iframe {
		// position: absolute;
		// top: 0px;
		// left: -16px;
		border-radius: 14px;
		border: none;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}

	.watch {
		text-align: center;
		font-family: $mainFontFamily;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;

		background: linear-gradient(182deg, #fff0de -32.57%, #ebc190 151.49%);
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;

		left: 83px;
		bottom: 20px;
		z-index: 11;
		position: absolute;
	}
}

.trailerMobile {
	display: none;
	position: relative;
	cursor: pointer;

	width: 383px;
	height: 247px;

	overflow: hidden;

	@include mediaMax(558px) {
		display: block;
	}

	.background {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 100;
	}

	.play {
		position: absolute;
		top: 99px;
		left: 167px;
		cursor: pointer;
		z-index: 100;
	}

	& > iframe {
		border: none;
		width: 286px;
		height: 186px;
		position: absolute;
		top: 37px;
		left: 48px;
		z-index: 99;
	}

	& > div {
		text-align: center;
		font-family: $mainFontFamily;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;

		background: linear-gradient(182deg, #fff0de -32.57%, #ebc190 151.49%);
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;

		left: 132px;
		bottom: 41px;
		z-index: 100;
		position: absolute;
	}
}

.displayNone {
	display: none;
}