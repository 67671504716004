@import "../../../../../app/styles/index";

.modal {
	height: 100vh;
	width: 100vw;
	background-color: rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(1px);
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	pointer-events: none;
	overflow: hidden;
	overflow-y: auto;
	transition: 0.5s;
	z-index: 100;

	&__content {
		transform: scale(0.5);
		transition: 0.5s;
	}

	&__content.active {
		transform: scale(1);
	}
}

.window_default {
	transition: 0.5s;
	min-width: 365px;
	min-height: 287px;
	// Background: #16132c;
	/* Note: backdrop-filter has minimal browser support */
	padding: 50px 30px 30px 30px;
	border-radius: 13px;

	background: linear-gradient(#0b0027 0 0) padding-box,
		/*this is your grey Background*/
			linear-gradient(
				198.57deg,
				#ebb16d 12.58%,
				rgba(214, 115, 0, 0.58) 113.05%
			)
			border-box;
	color: #313149;
	// padding: 10px;
	border: 2px solid transparent;
	display: inline-block;
}

:global(.modalAdaptive) {
	@include mediaMaxHeight(900px) {
		transform: scale(0.8);
	}
	@include mediaMaxHeight(700px) {
		transform: scale(0.7);
	}
}

.window_main {
	transition: 0.5s;
	min-width: 365px;
	min-height: 287px;
	background: #16132c;
	/* Note: backdrop-filter has minimal browser support */
	border-radius: 13px;
	background: linear-gradient(#16132c 0 0) padding-box,
		/*this is your grey Background*/
			linear-gradient(198.57deg, #ffa800 12.58%, #ff4d00 113.05%) border-box;
	color: #313149;
	padding: 10px;
	border: 2px solid transparent;
	display: inline-block;
	margin: 75px 0;
}

.modal.active {
	opacity: 1;
	pointer-events: all;
}

.modal__close_button {
	cursor: pointer;
	position: absolute;
	top: 15px;
	z-index: 10000;
	right: 15px;
	border: none;
	background: transparent;
}
