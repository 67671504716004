@import "../../../../../app/styles/index";

.blockArea {
	z-index: 10;
	position: absolute;
	width: 699px;
	height: 848px;
	background: rgba(11, 0, 39, 0.8);
	border-radius: 13px;
	display: grid;
	align-items: center;
	justify-items: center;
	grid-template-rows: 350px 150px;

	img {
		margin-top: 40px;
	}
}

.areaWarning {
	width: 434px;
	height: 150px;
	background: #2f0725;
	border-radius: 13px;
	display: grid;
	align-items: center;
	justify-items: center;
}

.textWarning {
	width: 311px;
	font-family: $subMainFontFamily;
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 140%;

	color: #c50000;
	text-align: center;
}
