@import "/src/app/styles/index";

.content {
	margin: 64px 0 0 0;

	.titleBlock {
		position: relative;
		display: flex;
		align-items: center;
		text-align: center;
		justify-content: center;
		width: 100%;

		background: url("../../../../../modules/mainPage/assets/img/png/partners.png")
				no-repeat center,
			#1a1922 50% / cover no-repeat;
		background-blend-mode: overlay;
		background-size: contain;
		height: 142px;

		margin-bottom: 100px;

		@include mediaMax(558px) {
			width: 309px;
			height: 82px;

			margin: auto;
			margin-bottom: 43px;
		}

		& > div {
			text-align: center;
			filter: drop-shadow(3px 1px 0px #7c4300);
			font-family: $mainFontFamily;
			font-size: 56px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			// -webkit-text-stroke: 1px #7c4300;

			background: linear-gradient(180deg, #ebb16d -56.25%, #e7e4e2 210.93%);
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			text-transform: lowercase;

			@include mediaMax(558px) {
				font-size: 32px;
			}
		}
	}

	.partners {
		.row {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			gap: 58px;
			max-width: 1092px;
			margin: auto;

			& > img {
				max-height: 59px;
			}

			@include mediaMax(558px) {
				gap: 10px;

				& > img {
					width: 84px;
				}
			}
		}

		& > div:nth-child(1) {
			margin-bottom: 58px;

			@include mediaMax(558px) {
				margin-bottom: 32px;

				& > img:nth-child(2) {
					width: 174.365px;
				}

				& > img:nth-child(3) {
					max-width: 65.185px;
				}
			}
		}

		& > div:nth-child(2) {
			& > img:nth-child(2) {
				max-height: 89.814px;
			}

			@include mediaMax(558px) {
				& > img:nth-child(2) {
					width: 148.98px;
				}

				& > img:nth-child(3) {
					max-width: 88.95px;
				}
			}
		}
	}
}
