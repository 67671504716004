@import "../../../../../app/styles/index";

.modal {
	display: grid;
	font-family: $subMainFontFamily;
	font-style: normal;
	width: 900px;
	height: 600px;
	color: #ffffff;
	background: url("../../../assets/img/backgrounds/backgroundSuccessfully.svg");
	border-radius: 13px;
	justify-items: center;
}

.title {
	width: 791px;
	font-weight: 800;
	font-size: 32px;
	line-height: 127%;
	text-align: center;
	margin-top: 26px;
}

.area {
	display: grid;
	grid-template-columns: 366px auto;
}

.checkNft {
	font-weight: 500;
	font-size: 15px;
	line-height: 127%;
	color: white;
}

.imgPack {
	height: 524px;
	position: relative;
	top: -120px;
	right: 20px;
}

.imgPackLeg {
	height: 524px;
	position: relative;
	top: -120px;
	right: 70px;
}

.imgPackCommon {
	height: 350px;
	position: relative;
	top: -20px;
}

.leftSide {
	display: grid;
	grid-template-rows: 340px auto;
	justify-items: center;
}

.blockchain {
	display: grid;
	align-items: center;
	justify-items: center;
	grid-template-columns: 38px 240px;
	column-gap: 10px;

	position: relative;
	top: -16px;

	img {
		width: 38px;
	}
}

.paragraph {
	font-weight: 500;
	font-size: 15px;
	line-height: 127%;
	width: 482px;
	margin-bottom: 46px;

	span {
		cursor: pointer;
		text-decoration: underline;
		font-weight: 700;
		color: rgb(231, 199, 157);
	}
}

.cross {
	position: absolute;
	right: 18px;
	top: 19px;
	cursor: pointer;
	height: 21px;
	width: 21px;
}

.socialNetwork {
	margin-top: 60px;
	display: grid;
	grid-template-columns: 19px 19px 19px 19px;
	column-gap: 26px;
}
