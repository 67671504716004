@import "/src/app/styles/index";

.content {
	width: 100%;
	display: flex;
	// justify-content: flex-end;
	flex-direction: column;
	align-items: center;
	text-align: right;
	margin-top: 300px;
	position: relative;
	@include mediaMax(1140px) {
		margin-top: 0;
	}
}

.title {
	text-align: center;
	z-index: 3;
	// max-width: 680px;
}

.pack{
	display: grid;
	justify-items: center;
	row-gap: 40px;
}

.flare {
	position: absolute;
	z-index: -1;
}

.titleSeason {
	display: flex;
	justify-content: center;
	margin-top: 100px;
	@include mediaMax($screenLg) {
		margin-top: 30px;
		width: 500px;
		img {
			width: 100%;
		}
	}
	@include mediaMax($screenSm) {
		img {
			width: 100%;
		}
		width: 390px;
		// margin-top: 0px;
	}

	.titleSeasonText {
		position: absolute;
		margin-top: 20px;
		font-family: $mainFontFamily;
		font-style: normal;
		font-weight: 400;
		font-size: 56px;
		line-height: 61px;
		background: linear-gradient(91.42deg, #ebb16d -5.95%, #e7e4e2 107.49%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
		text-fill-color: transparent;
		filter: drop-shadow(3px 1px 0px #7c4300);
		@include mediaMax($screenLg) {
			font-size: 40px;
			margin-top: 15px;
		}
		@include mediaMax($screenSm) {
			font-size: 25px;
			margin-top: 4px;
		}
	}
}

.titleMain {
	margin-top: 70px;
	font-family: $mainFontFamily;
	width: 100%;
	font-style: normal;
	font-weight: 400;
	font-size: 80px;
	line-height: 94px;
	background: linear-gradient(359.85deg, #edb676 -1.91%, #d67300 145.67%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
	text-align: center;

	filter: drop-shadow(-8px -6px 0px #842000);
	transform: matrix(1, 0, 0.07, 1, 0, 0);
	@include mediaMax($screenMd) {
		font-size: 45px;
		line-height: 64px;
	}
	@include mediaMax($screenSm) {
		font-size: 24px;
		margin-top: 30px;
		filter: drop-shadow(-3px -2px 0px #842000);
	}
}

.listCards {
	@include mediaMax(1130px) {
		display: none !important;
	}
}

.slider {
	display: flex;
	@include mediaMin(1130px) {
		display: none !important;
	}
}

.cards {
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-top: 210px;
	gap: 10px;
	@include mediaMax(1130px) {
		margin-top: 0;
		width: 120%;
	}

	.card {
		width: 377px;

		height: 532px;
		border-radius: 47px; /*1*/
		border: double 2px transparent;
		background-image: linear-gradient(#1a1922, #1a1922),
			radial-gradient(
				circle at top left,
				rgba(235, 177, 109, 1),
				rgba(214, 115, 0, 0.58)
			);
		background-origin: border-box;
		background-clip: content-box, border-box;
		position: relative;
		display: flex;
		flex-direction: column;
		@include mediaMax(1260px) {
			width: 327px;
			height: 502px;
		}

		.cardImage {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 370px;
			height: 370px;
			position: absolute;
			top: -125px;
			animation: infinite 5s upDown;
			@include mediaMax(1260px) {
				// transform: scale(0.8);
				transform: translate(-50%, 0) scale(0.8);
				left: 50%;
			}
		}

		.cardContent {
			margin-top: 275px;
			display: flex;
			flex-direction: column;
			padding: 0 19px;
			@include mediaMax(1260px) {
				margin-top: 245px;
			}

			.cardContentTop {
				display: flex;
				justify-content: space-between;

				.cardContentTitle {
					font-weight: 800;
					font-size: 20px;
					line-height: 139%;
					/* or 28px */

					display: flex;
					align-items: center;

					background: linear-gradient(
						184.31deg,
						#ebb16d -46.91%,
						#e7e4e2 118.36%
					);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					background-clip: text;
					text-fill-color: transparent;

					filter: drop-shadow(-1px 2px 0px #684318);
				}

				.cardContentRare {
					height: 24px;
					display: flex;
					justify-content: center;
					align-items: center;
					font-style: italic;
					font-weight: 700;
					padding: 0 10px;
					padding-bottom: 2px;
				}

				.rareCommon {
					color: #232323;
					background: #716e7b;
					border-radius: 21px;
				}

				.rareRare {
					color: #8f38fd;
					background: #452e62;
					border-radius: 24px;
				}

				.rareLegendary {
					color: #ff720d;
					background: #6b482f;
					border-radius: 32px;
				}
			}

			.cardContentCenter {
				display: flex;
				flex-direction: column;
				gap: 15px;
				margin-top: 30px;
				color: #ffffff;

				.cardContentLine {
					display: flex;
					justify-content: space-between;
					font-size: 16px;
					line-height: 20px;

					.cardContentLeft {
						font-weight: 400;
						text-align: left;
					}

					.cardContentRight {
						font-weight: 600;
						text-align: right;
					}

					.price {
						background: linear-gradient(
							180deg,
							#ebb16d -14.71%,
							#e7e4e2 123.53%
						);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
						background-clip: text;
						text-fill-color: transparent;
					}
				}
			}
		}
	}
}

.bottom {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 50px;

	.bottomImg {
		position: absolute;
		@include mediaMax($screenMd) {
			display: none;
		}
	}

	.btn {
		position: relative;
	}
}

.mySwiper {
	height: 800px;
	display: flex;
	align-items: center;
	justify-content: center;
	// padding-top: 120px;
	@include mediaMax(1260px) {
		height: 900px;
	}
}

.SwiperSlide {
	display: flex;
	justify-content: center;
	padding-bottom: 70px;
	align-items: flex-end;
	height: 860px;
}

.buttons{
	display: grid;
	grid-template-columns: 377px 377px 377px;
	justify-content: space-between;
	justify-items: center;
	column-gap: 10px;
	width: 100%;
	margin-top: 40px;

	@include mediaMax(1260px) {
		grid-template-columns: 327px 327px 327px;
	}

	@include mediaMax(1130px) {
		display: none !important;
	}
}

.buttons>*{
	cursor: pointer;
}

@keyframes upDown {
	0% {
		margin-top: 0;
	}
	50% {
		margin-top: -20px;
	}
	100% {
		margin-top: 0;
	}
}
