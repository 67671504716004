@import "/src/app/styles/index";

.preFooter {
	width: 100vw;
	height: 401px;
	margin-top: 150px;
	// background: #1a1922;
	display: flex;
	display: -webkit-flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
	align-items: center;
	background-image: url(../../../../mainPage/assets/img/png/Ground.png);
	background-repeat: none;
	background-size: 100%;
	@include mediaMax($screenLg) {
		height: 301px;
	}
	@include mediaMax($screenSm) {
		height: 201px;
	}

	.preFooterContainer {
		width: 100%;
		max-width: 1440px;
		margin-top: -30px;
		@include mediaMax($screenLg) {
			img {
				height: 300px;
			}
		}
		@include mediaMax($screenSm) {
			img {
				height: 200px;
			}
		}
	}

	.sparks {
		pointer-events: none;
		position: absolute;
		bottom: 0;
		width: 100vw;
		// height: 100%;
		z-index: 10;
		// background-image: url(../../assets/imageNew/Sparks.png);
		// background-size: 100%;
		// background-position: bottom;
	}
}
