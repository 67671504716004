@import "../../../../../app/styles/index";

.modal {
	padding-top: 20px;
	display: grid;
	font-family: $subMainFontFamily;
	font-style: normal;
	width: 700px;
	height: 908px;
	color: #ffffff;
	justify-items: center;
	background: url("../../../assets/img/backgrounds/backgroundSeectMethodPayment.png")
		no-repeat;
	@include mediaMaxHeight(900px) {
		transform: scale(0.8);
	}
	@include mediaMaxHeight(700px) {
		transform: scale(0.7);
	}
	@include mediaMax(570px) {
		transform: scale(1);
		position: relative;
		top: 170px;
		width: 340px;
		background: url("../../../assets/img/backgrounds/background340px908px.png")
			no-repeat;
	}
}

.cross {
	position: absolute;
	right: 31px;
	top: 30px;
	cursor: pointer;
	height: 21px;
	width: 21px;
}

.wrap {
	display: grid;
	width: 434px;
	grid-template-rows: auto auto auto 60px auto 76px auto auto auto;
	@include mediaMax(570px) {
		width: 340px !important;
	}
}

.title {
	font-weight: 800;
	font-size: 32px;
	line-height: 127%;
	text-align: center;
	margin-top: 16px;
	margin-bottom: 24px;
	@include mediaMax(570px) {
		width: 340px;
	}
}

.informQR {
	display: grid;
	font-weight: 700;
	font-size: 16px;
	line-height: 140%;
	grid-template-columns: 1fr 1fr;
	justify-self: start;
	@include mediaMax(570px) {
		grid-template-columns: auto;
		justify-self: center;
		justify-items: center;
		text-align: center;

		width: 340px;
		margin-left: -200px;
	}
}

.informSession {
	font-weight: 500;
	font-size: 14px;
	line-height: 140%;

	opacity: 0.7;
	justify-self: end;
	@include mediaMax(570px) {
		grid-row: 1;
		margin-right: 98px;
	}
}

.areaForQrCode {
	width: 430px;
	height: 288px;

	background: #281f41;
	border-radius: 18px;
	margin-bottom: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	@include mediaMax(570px) {
		width: 310px;
		margin-left: 15px;
	}
}

.wrapperQrCode {
	width: 250px;
	height: 250px;
	background: #ffffff;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	@include mediaMax(570px) {
	}
}

.warningWindow {
	box-sizing: border-box;

	display: grid;
	grid-template-columns: 35px auto;
	width: 434px;
	height: 55px;

	background: #281f41;
	border-radius: 15px;
	column-gap: 35px;
	align-items: center;
	padding-left: 42px;

	margin-bottom: 18px;

	@include mediaMax(570px) {
		width: 310px;
		padding-left: 10px;
		margin-left: 15px;
	}
}

.descriptionsWarning {
	width: 280px;
	@include mediaMax(570px) {
		width: 200px;
	}
}

.lineOr {
	width: 182px;
	height: 0;
	border: 1px solid #ffffff;
	@include mediaMax(570px) {
		width: 126px;
		position: relative;
		left: -55px;
	}
}

.textOr {
	@include mediaMax(570px) {
		position: relative;
		left: -55px;
	}
}

.wLine {
	@include mediaMax(570px) {
		margin-left: -110px;
	}
}

.areaSelectPayment {
	display: grid;
	grid-template-columns: 35px auto;
	width: 434px;
	height: 62px;

	border-radius: 15px;
	column-gap: 35px;
	align-items: center;
	margin-top: 6px;
	@include mediaMax(570px) {
		margin-left: 15px;
	}
}

.informAboutPay {
	margin-top: 10px;
	display: grid;
	grid-template-columns: auto 103px;
	height: 190px;
	align-items: center;
	margin-bottom: 10px;
	@include mediaMax(570px) {
		grid-template-columns: 210px 100px;
		margin-left: 15px;
	}
}

.copyButtonMob {
	@include mediaMax(570px) {
		position: relative;
		top: 20px;
	}
}

.copyButton {
	cursor: pointer;
	display: grid;
	width: 103px;
	height: 33px;
	grid-template-columns: auto auto;
	justify-content: center;

	background: #281f41;
	border-radius: 18px;
	align-items: center;
	justify-items: center;
	grid-column: 2;
	column-gap: 8px;
}

.textPay {
	grid-column: 1;
	width: 286px;
	word-break: break-all;
}

.line {
	width: 436px;
	height: 0;

	border: 1px solid #ffffff;
	@include mediaMax(570px) {
		width: 310px;
		margin-left: 15px;
	}
}

.bottomPart {
	display: grid;
	grid-template-columns: 166px 166px;
	column-gap: 102px;
	margin-top: 20px;
	@include mediaMax(570px) {
		column-gap: 10px;
		grid-template-columns: 150px 150px;
		margin-left: 15px;
	}
}

.buttonBack {
	cursor: pointer;
	font-weight: 700;
	font-size: 16px;
	line-height: 20px;

	display: grid;
	align-items: center;
	justify-items: center;

	color: #ffffff;
	background: url("../../../assets/img/backgrounds/backgroundBackButton.png")
		no-repeat;
	width: 166px;
	height: 44px;
	margin-bottom: 50px;
	@include mediaMax(570px) {
		margin-bottom: 20px;
		zoom: 0.9;
	}
}

.buttonPurchase {
	cursor: pointer;
	display: grid;
	align-items: center;
	justify-items: center;

	width: 166px;
	height: 44px;

	background: linear-gradient(176.42deg, #ebb16d -8.17%, #d67300 102.23%);
	box-shadow: 0 0 30px rgba(86, 176, 86, 0.19);
	border-radius: 21.5px;

	font-weight: 700;
	font-size: 16px;
	line-height: 20px;

	color: #ffffff;
	@include mediaMax(570px) {
		width: 150px;
	}
}
