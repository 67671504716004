@import "/src/app/styles/index";

.Menu {
	-webkit-text-fill-color: initial;
	text-fill-color: initial;
	background: url("../../../../mainPage/assets/img/svg/backgroundForSelectLang.svg")
		no-repeat;
	border-radius: 11px;
	position: absolute;
	top: 50px;
	right: 0;

	@include mediaMax(1150px) {
		left: 74px;
		top: 0;
		z-index: 11;
	}
}

.nameLanguage {
	cursor: pointer;
	font-family: $subMainFontFamily;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
	text-align: center;

	color: #ffffff;

	:hover {
		background: linear-gradient(180deg, #ebb16d -44.44%, #e7e4e2 194.44%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent !important;
		background-clip: text;
		text-fill-color: transparent;
	}
}

.WrapNameLanguage {
	display: grid;
	justify-items: center;
	align-items: center;
	height: 295px;
	width: 152px;
	padding-top: 10px;
	padding-bottom: 23px;
	row-gap: 9px;
}
