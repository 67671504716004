@import "/src/app/styles/index";

.content {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	margin-top: 200px;
	position: relative;
}

.title {
	text-align: right;
	z-index: 3;
	display: flex;
	justify-content: flex-end;
	width: 100%;
	@include mediaMax(1140px) {
		align-items: center;
		text-align: center;
		justify-content: center;
	}
}

.center {
	margin-top: 70px;
	position: relative;
	font-style: normal;
	font-weight: 700;
	font-size: 24px;
	line-height: 180.5%;
	display: flex;
	justify-content: space-between;
	color: #1c1209;
	@include mediaMax(1200px) {
		justify-content: center;
		margin-top: 90px;
	}
	@include mediaMax(1140px) {
		flex-wrap: wrap;
	}
}

.parchment {
	position: relative;
	display: flex;
	justify-content: center;
	// border: #fff 1px solid;
	// width: 300px;
	@include mediaMax(1200px) {
		width: 370px;
	}
	@include mediaMax(1140px) {
		width: 400px;
	}
}

.parchmentCenter {
	@include mediaMax(1200px) {
		margin-top: -50px;
	}
	@include mediaMax(1140px) {
		margin-top: 0;
	}
}

.parchmentImg {
	position: absolute;
	z-index: 2;
	bottom: 34px;
	left: 120px;
	height: 514px;
}

.parchmentImgBg {
	@include mediaMax(1350px) {
		height: 445px;
	}
}

.parchmentImgCube {
	height: 172px;
	@include mediaMax(1350px) {
		height: 149px;
		left: 50%;
		transform: translate(-55%, 0%);
	}
	@include mediaMax($screenLg) {
		transform: translate(-60%, 0%);
	}
}

.parchmentImgEye {
	height: 212px;
	@include mediaMax(1350px) {
		height: 181px;
		left: 50%;
		transform: translate(-40%, 0%);
	}
	@include mediaMax($screenLg) {
		transform: translate(-42%, 5%);
	}
}

.parchmentImgBook {
	height: 173px;
	@include mediaMax(1350px) {
		height: 160px;
		left: 50%;
		transform: translate(-50%, 0%);
	}
}

.parchmentTitle {
	top: 80px;
	position: absolute;
	font-size: 32px;
	max-width: 335px;
	text-align: center;
	@include mediaMax(1350px) {
		top: 60px;
	}
	@include mediaMax($screenLg) {
		font-size: 28px;
		max-width: 305px;
	}
}

.parchmentText {
	position: absolute;
	top: 180px;
	font-weight: 500;
	font-size: 18px;
	line-height: 163%;
	max-width: 295px;
	@include mediaMax(1350px) {
		top: 150px;
	}
}
