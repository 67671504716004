@import "../../../../../app/styles/index";

.packDescription {
	position: relative;
	left: 22px;
	height: auto;
	font-weight: 400;
	font-size: 17px;
	line-height: 127%;

	font-family: $subMainFontFamily;

	color: #ffffff;
	margin-bottom: 24px;

	.wrapList {
		display: grid;
		align-items: center;
		.li {
			margin-top: 28px;
			display: grid;
			grid-auto-flow: column;
			justify-content: start;
			align-items: center;

			position: relative;
			left: -6px;
			img {
				position: relative;
				left: -20px;
			}
		}
	}

	.point {
		background: url("../../../assets/img/png/point.png") no-repeat;
	}

	@include mediaMax(1250px) {
		width: 450px;
		position: relative;
		left: -20px;
		font-size: 15px;
		.wrapList {
			.li {
				margin-top: 20px;
				padding-left: 44px;

				position: relative;
				left: 40px;
			}
		}
	}
	@include mediaMax(800px) {
		width: 320px;
		margin-left: 18px;
		margin-top: -30px;
		margin-bottom: -30px;
	}
}
