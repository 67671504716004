@import "normalize.css";
@import "media";
@import "main";

:root {
	--animation-time: 3s;
	// --animation-time: 3s;
}

* {
	box-sizing: border-box;
}

.nws-modal-wrapper.open {
	z-index: 1001;

}


.container {
	max-width: 1440px;
	display: block;
	padding: 0 100px;
	@include mediaMax(1350px) {
		padding: 0 40px;
	}
	@include mediaMax(600px) {
		padding: 0 10px;
	}
}

img {
	image-rendering: -webkit-crisp-edges;
}

.titleSection {
	z-index: 2;
	font-family: $mainFontFamily;
	font-style: normal;
	font-weight: 400;
	font-size: 56px;
	line-height: 61px;
	color: #d5a975;

	filter: drop-shadow(6px 1px 0px #694821);
	@include mediaMax($screenLg) {
		font-size: 40px;
		// transform: scale(0.7);
	}
}
