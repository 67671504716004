@import "/src/app/styles/index";

.content {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	margin-top: 200px;
	position: relative;
	height: 100%;
	@include mediaMax(1140px) {
		margin-top: 50px;
	}
}

.title {
	position: relative;
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	width: 100%;
	min-width: 462px;

	background: url("../../../assets/img/png/PlateV3.png") no-repeat center;
	background-size: contain;
	height: 237px;

	.titleText {
		margin-top: 30px;
		font-family: $mainFontFamily;
		font-style: normal;
		font-weight: 400;
		font-size: 56px;
		line-height: 61px;

		background: linear-gradient(180deg, #a8610d 0%, #ebc190 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;

		filter: drop-shadow(3px 1px 0px #7c4300);

		@include mediaMax($screenLg) {
			font-size: 32px;
			margin-top: 20px;
			line-height: 35px;
		}
	}

	@include mediaMax($screenLg) {
		height: 150px;
	}
	@include mediaMax($screenMd) {
		height: 110px;
	}
}

.roadmapSteps {
	@include mediaMax(1200px) {
		display: none;
	}
}

.stage {
	border-radius: 26px;
	border: 2px solid transparent; /*2*/
	background: linear-gradient(
			45deg,
			rgba(235, 177, 109, 1),
			rgba(214, 115, 0, 1)
		)
		border-box; /*3*/
	-webkit-mask: /*4*/ linear-gradient(#fff 0 0) padding-box,
		linear-gradient(#fff 0 0);
	-webkit-mask-composite: xor; /*5'*/
	mask-composite: exclude; /*5*/
	position: relative;
	@include mediaMax(1350px) {
		width: 503px !important;
	}
	@include mediaMax($screenMd) {
		width: 350px !important;
	}
}

.roadmapSteps {
	position: absolute;
	top: 500px;
}

.roadmapWrapper {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-top: 100px;
	@include mediaMax(1080px) {
		flex-direction: column;
		align-items: center;
		gap: 10px;
	}
}

.stepImg {
	display: none;

	& > img {
		height: 153px;
	}

	@include mediaMax(1080px) {
		display: block;
		z-index: 10;
	}
}

.stageWrapper {
	position: relative;
	// border: 1px solid #fff;
	height: min-content;
	@include mediaMax(1350px) {
		width: 503px !important;
	}
	@include mediaMax(1080px) {
		margin-left: 0 !important;
		margin-right: 0 !important;
		margin-top: 0 !important;
	}
	@include mediaMax($screenMd) {
		width: 350px !important;
	}
}

.stageNumber {
	width: 62px;
	height: 62px;
	margin-left: 30px;
	background: linear-gradient(180deg, #ebb16d -38.96%, #d67300 103.89%);
	position: absolute;
	left: 0;
	border-radius: 50%;
	font-family: $mainFontFamily;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 400;
	font-size: 32px;
	line-height: 121%;
	padding-top: 3px;
	color: #ffffff;
	top: -31px;
	z-index: 5;
	@include mediaMax($screenMd) {
		font-size: 22px;
		width: 40px;
		height: 40px;
		top: -20px;
	}
}

.stageNumberQ {
	width: 106px;
	height: 45px;
	background: #795d3f;
	border-radius: 22.5px;
	margin-right: 30px;

	position: absolute;
	right: 0;

	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 800;
	font-size: 16px;
	line-height: 121%;

	color: #ffffff;
	top: -22.5px;
	z-index: 5;
	@include mediaMax($screenMd) {
		font-size: 14px;
		width: 70px;
		top: -15px;
		height: 30px;
	}
}

.stageNumberDone {
	margin-right: 30px;
	position: absolute;
	right: 0;
	top: 50px;
	z-index: 5;
	@include mediaMax($screenMd) {
		top: 20px;
		svg {
			width: 40px;
		}
	}
}

.stage1 {
	width: 543px;
	height: 401px;
}

.stageWrapper1 {
	width: 543px;
	height: 401px;
	margin-left: -50px;
	@include mediaMax(1350px) {
		margin-left: -25px;
	}
}

.stage2 {
	width: 543px;
	height: 486px;
}

.stageWrapper2 {
	width: 543px;
	height: 486px;
	margin-top: 200px;
	margin-right: -50px;
	@include mediaMax(1350px) {
		margin-right: -25px;
	}
}

.stage3 {
	width: 543px;
	height: 486px;
}

.stageWrapper3 {
	width: 543px;
	height: 486px;
	margin-top: -200px;
	margin-left: -50px;
	@include mediaMax(1350px) {
		margin-left: -25px;
	}
}

.stage4 {
	width: 543px;
	height: 486px;
	@include mediaMax($screenMd) {
		height: 406px;
	}
}

.stageWrapper4 {
	width: 543px;
	height: 486px;
	margin-top: 100px;
	margin-right: -50px;
	@include mediaMax(1350px) {
		margin-right: -25px;
	}
	@include mediaMax($screenMd) {
		height: 406px;
	}
}

.stage5 {
	width: 543px;
	height: 486px;
}

.stageWrapper5 {
	width: 543px;
	height: 486px;
	margin-left: -50px;
	margin-top: -200px;
	@include mediaMax(1350px) {
		margin-left: -25px;
	}
}

.stage6 {
	width: 543px;
	height: 486px;
}

.stageWrapper6 {
	width: 543px;
	height: 486px;
	margin-top: 100px;
	margin-right: -50px;
	@include mediaMax(1350px) {
		margin-right: -25px;
	}
}

.stageTitle {
	left: 55px;
	top: 65px;
	position: absolute;
	font-family: $mainFontFamily;
	font-style: normal;
	font-weight: 400;
	font-size: 32px;
	line-height: 61px;

	color: #d99347;

	@include mediaMax(1080px) {
		font-size: 24px;
	}
	@include mediaMax($screenMd) {
		left: 16px;
		top: 40px;
	}
}

.dot {
	display: flex;
	align-items: center;
	box-sizing: border-box;
	margin-top: 5px;
	width: 13px;
	flex: 0 0 auto;
	height: 13px;
	border-radius: 50%;
	background: linear-gradient(180deg, #ebb16d -38.96%, #d67300 103.89%);
}

.tabs {
	left: 55px;
	position: absolute;
	top: 120px;
	display: flex;
	flex-direction: column;
	gap: 25px;

	.tab {
		display: flex;
		gap: 32px;
		@include mediaMax($screenMd) {
			gap: 20px;
		}
	}

	@include mediaMax($screenMd) {
		left: 16px;
		top: 90px;
		gap: 20px;
	}
}

.tabs1 {
	gap: 20px;
}

.tabs2 {
	gap: 15px;
}

.tabs3 {
	gap: 20px;
}

.text {
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	display: flex;
	align-items: flex-start;
	text-align: left;
	height: auto;
	color: #ffffff;
	@include mediaMax(1080px) {
		font-size: 16px;
	}
}

.flare {
	position: absolute;
}
